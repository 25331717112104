import React, { Component } from 'react';
import './contact.css';

export class Contact extends Component {
    static displayName = "Nous joindre";

    render() {
        return (
            <div className="contenu">
                <h4 className="display-4 mt-4">Pour nous joindre</h4>

                <hr className="my-4" />

                <p>Vous êtes un fournisseur qui offrez une formation aux RSGE sur un des sujets suivants :</p>

                <ul>
                    <li>le rôle de la responsable d’un service de garde en milieu familial ;</li>
                    <li>le développement de l’enfant ;</li>
                    <li>la sécurité, la santé et l’alimentation ;</li>
                    <li>le programme éducatif.</li>
                </ul>

                <p>
                    Vous aimeriez faire ajouter votre formation dans ce répertoire? Veuillez transmettre un courriel à l’adresse suivante : <a href="mailto:mfa.perfectionnement.rsge@mfa.gouv.qc.ca">mfa.perfectionnement.rsge@mfa.gouv.qc.ca.</a>
                </p>

                <p>Vous ne recevrez pas de message de confirmation une fois votre courriel envoyé et aucun délai de traitement ne vous sera communiqué.</p>
            </div>
        );
    }
}